.box {
  padding: 168px 0 61px 0;

  &__item {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: .2s;
    height: calc(100% - 30px);
    margin-bottom: 30px;
    padding: 44px 16px 18px 16px;
    position: relative;

    &:hover {
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.5);
      transition: .2s;
    }

    &.box__item-big {
      padding: 44px 49px 38px 63px;
      display: flex;
      align-items: center;

      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        transition: .2s;
      }

      & .box__img {
        order: 2;
        width: 62%;
        margin-left: auto;
        margin-bottom: 0;
          display: flex;
          justify-content: space-between;
      }

      & .box__text {
        text-align: left;
        width: 393px;
        max-width: 100%;
        order: 1;
      }

      & .box__heading {
        margin-bottom: 37px;

        & .h3 {
          font-weight: 600;
          font-size: 29px;
          line-height: 35px;
          color: #000000;
        }
      }

      & .box__characteristics {
        justify-content: flex-start;
        margin-bottom: 47px;
      }

      & .box__footer {
        display: flex;
        align-items: center;

        & .btn.btn-primary {
          flex: 1;
          max-width: 225px;
        }
      }

      & .box__price {
        margin-bottom: 0;
        flex-wrap: wrap;
        margin-right: 0;

        & .discount {
          order: 1;
          width: 100%;
          margin-bottom: 11px;
        }

        & .sum {
          margin-right: 0;
          order: 2;
          width: 100%;
        }
      }
    }
  }

  &__img {
    margin-bottom: 37px;
    width: 100%;
    text-align: center;

    & img {
      //max-width: 100%;
      //max-height: 100%;
    }
  }

  &__note {
    position: absolute;
    left: 0;
    top: 0;
    color: #FFFFFF;
    border-radius: 5px 0 5px 0;
    white-space: nowrap;

    &.box__note-benefit {
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      color: #FFFFFF;
      background: linear-gradient(267.89deg, #7BC06A -11.15%, #48B37F 105.49%);
      display: flex;
      align-items: center;
      height: 43px;
      padding: 10px 24px 10px 11px;

    }

    & span {
      margin-right: 11px;
      width: 23px;
      height: 23px;
    }

    &.box__note-double {
      background: linear-gradient(180deg, #E7C3A1 0%, #F2D4C3 100%);
      font-weight: 600;
      font-size: 20px;
      line-height: 45px;
      color: #FFFFFF;
      height: 50px;
      padding: 2px 15px;
    }
  }

  &__text {
    text-align: center;
  }

  &__heading {
    margin-bottom: 20px;


    & p {
      font-size: 13px;
      line-height: 169%;
      color: #686868;
      margin-bottom: 7px;
    }

    & .h3 {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__characteristics {
    margin-bottom: 31px;
    display: flex;
    justify-content: center;

    & table {
      max-width: 200px;

      & tr {
        & td {
          font-size: 13px;
          line-height: 169%;
          color: #686868;
        }
      }
    }
  }

  &__footer {
    & .btn.btn-primary {
      width: 100%;
    }
  }

  &__price {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
}

.sum {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
  margin-right: 21px;
  white-space: nowrap;
}

.discount {
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: line-through;
  color: #868686;
  white-space: nowrap;
}


@media (max-width: 1023px) {
  .box {
    padding: 98px 0 40px 0;
  }

}

@media (max-width: 1199px) {
  .box__heading .h3 {
    font-size: 20px;
    line-height: 25px;
  }
  .box__item.box__item-big .box__heading .h3 {
    font-size: 25px;
    line-height: 30px;
  }
  .box__item.box__item-big .box__heading {
    margin-bottom: 20px;
  }
  .box__item.box__item-big {
    padding: 44px 20px 25px 30px;
  }
  .box__note.box__note-benefit {
    font-size: 13px;
    line-height: 15px;
    height: 35px;
  }
  .sum {
    font-size: 25px;
    line-height: 29px;
  }
}

@media (max-width: 991px) {
  .box__item.box__item-big .box__text {
    width: 310px;
  }
  .box__item.box__item-big .box__characteristics {
    margin-bottom: 20px;
  }
  .discount {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 767px) {
  .box__item {
    height: auto;
    margin-bottom: 15px;
    padding: 20px 15px 15px 15px;
  }
  .box {
    padding: 90px 0 40px 0;
  }
  .box__img {
    margin-bottom: 20px;
  }
  .box__item.box__item-big .box__text {
    width: 100%;
    order: 2;
  }
  .box__item.box__item-big .box__img {
    width: 100%;
  }
  .box__item.box__item-big {
    padding: 20px 15px 15px 15px;
    display: block;
  }
}
