//fullpage
@use "sass:meta";
@use "fonts";
@use "system/fullpage";

// Определение своих значений
@use "variables";

// Подключение нужных SCSS исходников Bootstrap 4
@import "~bootstrap/dist/css/bootstrap.min.css";
@include meta.load-css("~bootstrap-icons/font/bootstrap-icons");
// Подключение нужных SCSS исходников
@import '~swiper/css/swiper.min.css';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import '~simplebar/dist/simplebar.min.css';
//system

@include meta.load-css("system/mixin");
@include meta.load-css("system/settings");
@include meta.load-css("system/form");
@include meta.load-css("system/modal");
@include meta.load-css("system/sprite");
//section
@include meta.load-css("section/header");
@include meta.load-css("section/footer");
@include meta.load-css("section/home");
@include meta.load-css("section/box");
@include meta.load-css("section/card");
@include meta.load-css("section/status");
// Подключение своих SCSS файлов
@include meta.load-css("my");
@import "video-js.css";
