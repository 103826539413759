@use "../system/mixin.scss" as mixin;

.card {
  padding: 140px 0 0 0;
  background: #F7F8F7;

  & .page-heading {
    & .box__note {
      display: inline-block;
      position: relative;
      left: auto;
      top: auto;
      margin-bottom: 20px;
      border-radius: 90px;
    }


  }

  &__tabs {

    position: relative;
    z-index: 10;

    .nav-tabs .nav-link {
      background: #FFFFFF;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      position: relative;
      transition: .5s;
      font-size: 18px;
      line-height: 35px;
      text-align: center;
      color: #7C7C7C;
      height: 51px;

      &:hover {
        transition: .5s;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.55);
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 96%;
        height: 50px;
        background: #43A45E;
        opacity: 0;
        visibility: hidden;
      }
    }

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
      font-weight: 600;
      font-size: 22px;
      background: #43A45E;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      color: #FFFFFF;

      &:after {
        opacity: 1;
        visibility: visible;
      }
    }

    .nav-tabs {
      display: flex;
      align-items: center;
      padding-bottom: 26px;
      position: relative;
      border: none;
      margin: -10px;
      flex-wrap: nowrap;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: -2000px;
        right: -2000px;
        bottom: 10px;
        height: 6px;
        background: #43A45E;
      }

    }

    .nav-tabs .nav-item {
      padding: 0 10px;
      flex: 1;
    }

    & .tab-content {
      padding: 66px 0 76px 0;
      position: relative;
      z-index: 10;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: -2000px;
        right: -2000px;
        top: 0;
        bottom: 0;
        background: #FFFFFF;
        z-index: -1;
      }
    }

    .tab-content {
      transition: .5s;
    }
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
  }

  @media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1180px;
    }
  }


}

.pricing {
  width: 43%;
  padding-right: 51px;
  display: flex;
  flex-direction: column;
}

.pricing__item {
  margin-bottom: 47px;

  &:last-child {
    margin-bottom: 0;
    //margin-top: auto;
  }

  & .h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    color: #000000;
    margin-bottom: 7px;
  }

  & p {
    color: #000000;
    margin-bottom: 34px;
  }

  .sum {
    font-weight: bold;
    font-size: 35px;
    line-height: 71%;
    margin-bottom: 34px;

    &.sum-green {
      color: #43A45E;
    }

    &.sum-blue {
      color: #6073D9;
    }
  }

  .pricing__decline {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #597EDC;
    margin-top: 33px;

  }

  & .btn.btn-primary {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 26px;

    & span:last-child {
      margin-left: auto;
      font-size: 21px;
      color: #FFFFFF;
    }

    &:after {
      display: none;
    }
  }

  .pricing__note {
    color: #818181;
    margin-top: 23px;
  }
}


.info {
  & .pricing__item {
    display: none;
  }

  position: relative;
  padding-left: 70px;
  width: 57%;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #DFDFDF;
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.05);
  }

  & .h2 {
    font-weight: 600;
    font-size: 33px;
    line-height: 39px;
    color: #000000;
    margin-bottom: 16px;
  }

  .pricing__item {
    top: 0;
  }

  &__heading {
    position: relative;
    //padding-bottom: 60px;
    padding-right: 70px;

    & .h2 {
      margin-bottom: 16px;
    }
  }

  &__include {
    position: relative;

    & .h2 {
      margin-bottom: 24px;
    }

    & .pricing__item {
      top: 60px;
    }

    & .btn.btn-outline-secondary {
      margin-bottom: 35px;
    }
  }

  &__video {
    position: relative;

    & .h2 {
      margin-bottom: 37px;
    }
  }

  &__photo {
    display: flex;
    flex-wrap: wrap;
    margin: -17.5px -6px;
    justify-content: space-between;
  }


}


.photo {
  margin-bottom: 30px;

  &__gallery {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;

    & .photo__img {
      width: calc(50% - 8px);
      margin: 4px;
    }
  }

  &__item {
    //width: calc(25% - 12px);
    margin: 17.5px 6px;
      text-align: center;

    &.photo__item-big {
      width: calc(50% - 12px);
    }
  }

  &__img {
    //overflow: hidden;

    & img {
        max-height: 150px;
      //width: 100%;
      //height: 100%;
    }
  }

  &__text {

    margin-top: 5px;
    text-align: center;

    & span {
      padding: 3px 3px;
      font-size: 12px;
      line-height: 16px;
      color: #696969;
        display: block;
    }
  }


}

.package {
  margin-bottom: 41px;

  & .h3 {
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 32px;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: -9.5px -7.5px;
  }

  &__item {
    width: calc(33.3% - 15px);
    margin: 9.5px 7.5px;
    padding: 20px 12px 20px 24px;
    background: #F4F4F4;
    border-radius: 5px;
    font-size: 17px;
    line-height: 21px;
    color: #000000;
  }
}

.video {
  margin-bottom: 32px;

  & .h2 {
    margin-bottom: 37px;
  }

  &__block {
    position: relative;
    display: block;
    margin-bottom: 24px;

    &::before {
      content: '';
      display: block;
      padding-top: 70%;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    & img {
      width: 100%;
      height: 100%;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }
  }

  &__play {
    position: absolute;
    left: 24px;
    bottom: 19px;
    border: 1.5px solid #FFFFFF;
    border-radius: 90px;
    height: 47px;
    font-weight: bold;
    font-size: 17px;
    line-height: 36px;
    color: #FFFFFF;
    padding: 5px 23px 5px 22px;

    & span {
      margin-right: 16px;
      transition: none;
    }

    &:hover {
      background: #FFFFFF;
      color: #000000;
    }
  }

  &__text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 53%;
    transform: translate(-50%, -50%);
    font-weight: 900;
    font-size: 90px;
    line-height: 25px;
    letter-spacing: 0.4em;
    color: rgba(255, 255, 255, 0.5);

  }

}


.important {
  & .h2 {
    margin-bottom: 45px;

    & span {
      margin-right: 24px;
    }
  }

  & .h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 35px;
  }

  &__img {
    width: 100%;
    display: block;
    position: relative;

    &:before {
      content: '';
      display: block;
      padding-top: 56.8%;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 20px;
      right: 20px;
      top: 90%;
      height: 88px;
      background: linear-gradient(180deg, rgba(140, 140, 140, 0.26) 0%, rgba(255, 255, 255, 0) 100%);
      filter: blur(10px);
      z-index: 0;
    }

    & img {
      z-index: 2;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  & ul {
    counter-reset: num;
    margin-bottom: 50px;

    & li {
      list-style-type: none;
      font-size: 17px;
      line-height: 25px;
      margin-bottom: 26px;
      padding-left: 65px;

      position: relative;

      &:before {
        content: counter(num);
        counter-increment: num;
        @include mixin.verticalCenter;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-weight: bold;
        font-size: 17px;
        line-height: 25px;
        color: #000000;
        width: 42px;
        height: 42px;
        text-align: center;
        border-radius: 50%;
        border: 1.5px solid #FFC09C;
      }

      &:last-child {
        margin-bottom: 0;
      }

      & span {
        font-weight: 600;
        color: #E98046;
      }
    }
  }
}

.page-heading.page-heading-bg {
  margin-bottom: 66px;
  position: relative;
  z-index: 2;

}


//media

@media (max-width: 1439px) {
  .video__text {
    font-size: 70px;
  }
}

@media (max-width: 1199px) {
  .package__item {
    padding: 15px 10px 15px 10px;
    font-size: 16px;
    line-height: 20px;
  }
  .pricing__item .h3 {
    font-size: 20px;
    line-height: 21px;
  }
  .info .h2 {
    font-size: 30px;
    line-height: 34px;
  }
  .info__heading {
    padding-right: 0;
    padding-bottom: 30px;
  }
  .info {
    padding-left: 40px;
  }
  .pricing {
    padding-right: 30px;
  }
  .important .h3 {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 25px;
  }
  .important ul li:before {
    font-size: 16px;
    line-height: 22px;
    color: #000;
    width: 35px;
    height: 35px;
  }
  .important ul li {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
    padding-left: 50px;
  }
  .card__tabs .tab-content {
    padding: 40px 0 50px 0;
  }

}

@media (max-width: 1023px) {
  .video__text {
    font-size: 50px;
  }
  .card {
    padding-top: 90px;
  }
  .pricing__item .h3 {
    font-size: 18px;
    line-height: 19px;
  }
  .info .h2 {
    font-size: 26px;
    line-height: 30px;
  }
}

@media (max-width: 991px) {
  .card__wrapper {
    display: block;
  }
  .pricing {
    display: none;
  }
  .info {
    padding-left: 0;
    width: 100%;

    &:before {
      display: none;
    }
  }
  .info {
    & .pricing__item {
      display: block;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 767px) {
  .info .h2 {
    font-size: 22px;
    line-height: 24px;
  }
  .info__heading {
    padding-bottom: 20px;
  }
  .pricing__item .sum {
    font-weight: 700;
    font-size: 30px;
    line-height: 71%;
    margin-bottom: 20px;
  }
  .pricing__item .pricing__decline {
    margin-top: 20px;
  }
  .info__include .h2 {
    margin-bottom: 15px;
  }
  .pricing__item .h3 {
    font-size: 16px;
    line-height: 16px;
  }
  .pricing__item p {
    margin-bottom: 20px;
  }
  .package .h3 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .package__wrap {
    margin: -5px;
  }
  .video .h2 {
    margin-bottom: 20px;
  }
  .important .h2 {
    margin-bottom: 20px;
  }
  .important .h2 span {
    margin-right: 15px;
    width: 25px;
    height: 25px;
  }
  .important .h3 {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 20px;
  }
  .important ul li {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 15px;
    padding-left: 40px;
  }
  .important ul {
    margin-bottom: 20px;
  }
  .important ul li:before {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    width: 25px;
    height: 25px;
  }
  .package__item {
    padding: 10px;
    font-size: 14px;
    line-height: 17px;
    width: calc(33.3% - 10px);
    margin: 5px;
  }
  .card__tabs .nav-tabs .nav-item.show .nav-link, .card__tabs .nav-tabs .nav-link.active {
    font-size: 18px;
  }
  .card__tabs .nav-tabs .nav-link {
    font-size: 16px;
    line-height: 23px;
    height: 40px;
    padding: 0.5rem 0.2rem;

  }
  .page-heading.page-heading-bg {
    margin-bottom: 46px;
  }
}

@media (max-width: 567px) {
  .card__tabs .nav-tabs {
    display: block;
  }
  .card__tabs .nav-tabs .nav-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .card__tabs .nav-tabs .nav-link:after {
    display: none;
  }
    .photo__item.xs-full-width {
        order: 1;
        width: calc(100% - 12px);
        & img {
            max-height: none;
        }
    }
    .photo__item.xs-half-width {
        order: 2;
        width: calc(50% - 12px);
        & img {
            max-height: none;
        }
    }
  //
  //.photo__item {
  //  width: calc(50% - 12px);
  //}
  .video__play {
    left: 20px;
    bottom: 19px;
    height: 30px;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 15px 5px 10px;
  }
  .video__play span {
    margin-right: 10px;
  }
  .video__text {
    font-size: 40px;
  }
  .package__item {
    width: calc(50% - 10px);
  }
  .pricing__item .btn.btn-primary {
    padding: 10px 15px;
  }
}
