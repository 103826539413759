@use "../system/mixin.scss" as mixin;

.status-wrapper {
  padding: 150px 50px;
  @include mixin.verticalCenter;
}

.status-item {
  border: 1px dashed #D03C1B;
  border-radius: 10px;
  max-width: 440px;
  width: 100%;
  margin: 0 auto 58px auto;
  padding: 50px 55px 68px 55px;
  @include mixin.verticalCenter;
  text-align: center;

  & .btn.btn-primary {
    margin-top: 18px;
    padding: 5px 48px;

    &:after {
      display: none;
    }
  }
}

.status-image {
  margin-bottom: 30px;
  @include mixin.verticalCenter;

  &.status-image-right {
    margin-left: 40px;
  }

  &.status-image-small {
    height: 95px;
  }

  & img {
    max-width: 100%;
    max-height: 100%;
  }
}


.status-text {
  & .h3 {
    max-width: 294px;
    margin: 0 auto 18px auto;
    font-weight: bold;
    font-size: 20px;
    line-height: 125%;
    text-align: center;
    color: #000000;
  }

  & p {
    max-width: 340px;
    margin: 0 auto;
    font-size: 17px;
    line-height: 147%;
    text-align: center;
    color: #000000;
  }
}

@media (max-width: 1023px) {
  .status-wrapper {
    padding: 100px 15px;
  }
}

@media (max-width: 767px) {
  .status-item {
    padding: 30px 25px 40px 25px;
  }
  .status-image {
    margin-bottom: 20px;
  }
  .status-text .h3 {
    font-size: 18px;
  }
  .status-text p {
    max-width: 300px;
    font-size: 15px;
  }
}

@media (max-width: 567px) {
  .status-text .h3 {
    font-size: 16px;
  }
  .status-image {
    height: 130px;
  }
  .status-item {
    padding: 15px 10px;
  }
}
