.footer {
    margin-top: 40px;
    background: #0650BF;
    padding: 20px;
    color: #fff;
}

.footer a{
    color: #fff;
}
