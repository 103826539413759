.modal {
    min-width: 720px;
    width: auto;
    height: auto;
}

//preloader

.loaderArea {
  background: #FFFFFF;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
}

.loader {
  height: 100px;
  width: 270px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  text-align: center;
  animation: preloader-animate 1s ease;
}

.loader-bar {
    display: block;
    margin: 30px auto 0;
    border-radius: 5px;
    width: 260px;
    height: 5px;
    background-color: #eceeef;
    overflow: hidden;
}

.loader-bar-filled {
    transition: width 0.5s ease-out;
    border-radius: 5px;
    background-color: #0650BF;
    width: 0%;
    height: 100%;
}

.loader-text {
    margin-top: 8px;
    color: #999;
    font-size: 14px;
}

@keyframes preloader-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.btn.btn-primary {
  text-align: left;
  height: 66px;
  position: relative;
  border-radius: 5px;
  transition: .5s;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 20px;
  line-height: 52px;
  color: #FFFFFF;
  position: relative;
  background: #43A45E;
  padding-left: 26px;
    padding-right: 55px;

  & span {
    position: relative;
    z-index: 2;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(267.89deg, #7BC06A -11.15%, #48B37F 105.49%);
    border-radius: 5px;
    opacity: 0;
    z-index: 0;
  }

  & .btn__item {
    position: relative;
    z-index: 2;

    & span {
      margin-right: 16px;
      font-size: 25px;
    }
  }

  &:hover {
    transition: .5s;
    background: rgba(41, 129, 66, 0.5);

    &:before {
      opacity: 1;
    }
  }


  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 27px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 14px;
    background: url("../img/icons/arrow-right.svg") no-repeat center;
  }

  &.btn-blue {
    background: #6073D9;
    box-shadow: 0px 4px 4px rgba(41, 55, 129, 0.2);
  }
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  background: #43A45E;
  box-shadow: 0px 4px 4px rgba(41, 129, 66, 0.0);
  border-color: transparent;
}

.btn.btn-secondary {
  background: #3065ED;
  border-radius: 90px;
  padding: 10px 17px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  height: 42px;

  & span {
    margin-right: 16px;
    font-size: 22px;
    transition: none;
  }

  &:hover {
    background: #FFFFFF;
    color: #3065ED;
  }
}

.btn.btn-outline-primary {
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  height: 40px;
  padding: 10px 35px 10px 20px;

  & span {
    margin-right: 17px;
    color: #BABABA;
    transition: none;
  }

  &:hover {
    background: #43A45E;
    border: 1px solid #43A45E;
    color: #FFFFFF;

    & span {
      color: #FFFFFF;
    }
  }
}

.btn.btn-outline-secondary {
  border: 1px solid #CBCBCB;
  border-radius: 5px;
  padding: 8px 15px 9px 18px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #597EDC;

  & span {
    margin-left: 20px;
    color: #000000;
    transition: none;
  }

  &:hover {
    background: #CBCBCB;
    color: #FFFFFF;
  }
}

.package__wrap > .feature-with-image {
    width: 100%;
}
.package__wrap > .feature-with-image.full-width {
    width: 100%;
}
.package__wrap > .feature-with-image.half-width {
    width: calc( 50% - 12px );
}
.package__wrap > .feature-with-image {
    margin-bottom: 25px;
}
.package__wrap > .feature-with-image > .image-wrapper {
    display: flex;
    justify-content: space-between;
}
.package__wrap > .feature-with-image.many-images > .image-wrapper > img {
    max-width: calc( 50% - 5px );
}
.package__wrap > .feature-with-image > .description-wrapper {
}

//media

@media (max-width: 1599px) {
  .btn.btn-primary {
    font-size: 18px;
    line-height: 39px;
    height: 50px;
      padding-right: 55px;
  }
    .modal {
        min-width: 720px;
    }

}

@media (max-width: 1439px) {
  .btn.btn-primary {
    font-size: 18px;
      padding-right: 55px;
  }
  .btn.btn-primary:after {
    right: 15px;
  }
    .modal {
        min-width: 720px;
    }

}

@media (max-width: 1299px) {
  .btn.btn-primary {
    padding-left: 16px;
      padding-right: 35px;
  }
  .btn.btn-primary {
    font-size: 16px;
  }
  .btn.btn-secondary span {
    font-size: 18px;
  }
    .modal {
        min-width: 720px;
    }
}

@media (max-width: 1024px) {
  .loaderArea {
    display: none;
  }
  .btn.btn-outline-primary {
    padding: 5px 15px 5px 10px;
    height: 30px;
  }
  .header {
    padding: 7px 0;
  }
  .header .logo {
    width: 96px;
    height: 36px;
  }
    .modal {
        min-width: auto;
    }
}


//@media (max-width: 767px) {
//  .main-wrapper:before {
//    display: none;
//  }
//    .package__wrap > .feature-with-image > .description-wrapper h4 {
//        font-size: 1.1rem;
//    }
//    .package__wrap > .feature-with-image > .description-wrapper p {
//        font-size: 12px;
//    }
//
//}
//
//@media (max-width: 567px) {
//  .header .btn.btn-outline-primary {
//    font-size: 0;
//    padding: 5px 10px;
//
//    & span {
//      margin-right: 0;
//      font-size: 20px;
//    }
//  }
//}

.vue-dropzone,
.vue-dropzone * {
    box-sizing: border-box;
}

.vue-dropzone {
    position: relative;
    border: 2px dashed #7dc84b;
}
.vue-dropzone.dz-clickable {
    cursor: pointer;
}
.vue-dropzone.dz-clickable:before {
    content: 'Загрузка файлов';
}
.vue-dropzone .dz-preview {
    position: relative;
    display: inline-block;
    width: 120px;
    margin: 0.5em;
}

.vue-dropzone .dz-preview .dz-progress {
    display: block;
    height: 15px;
    border: 1px solid #aaa;
}

.vue-dropzone .dz-preview .dz-progress .dz-upload {
    display: block;
    height: 100%;
    width: 0;
    background: green;
}

.vue-dropzone .dz-error-message {
    color: red;
    display: none;
}

.vue-dropzone .dz-preview.dz-error .dz-error-message,
.vue-dropzone .dz-preview.dz-error .dz-error-mark {
    display: block;
}

.vue-dropzone .dz-preview.dz-success .dz-success-mark {
    display: block;
}

.vue-dropzone .dz-preview .dz-error-mark,
.vue-dropzone .dz-preview .dz-success-mark {
    position: absolute;
    display: none;
    top: 30px;
    width: 54px;
    height: 58px;
    left: 50%;
    margin-left: -27px;
}

.text-orange {
    color: #fd7e14!important;
}

h1, h2, h3, h4, h5, h6 {
    color: #435570;
}

hr {
    border-top: 1px solid #B2D6EB;
}

:root {
    --warning-red: #F83E54;
    --main-green: #52A829;
}
.slider-main {
    min-height: 400px;
    padding: 10px 0 0;
    margin: 20px 0;
    border-radius: 4px;
    box-shadow: 0 4px 8px 1px #bbb;
}

.slider-main .swiper-slide:first-child {
    width: 500px;
    max-width: 90%;
}
.slider-main .slider-label {
    min-height: 25px;
    font-weight: bold;
    font-size: 1.1em;
}
.slide-product-description li{
    list-style: square inside;
}
.slide-product-description {
    height: 385px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
}

.slider-main .swiper-slide {
    width: unset;
}

.image-in-line {
    height: 385px;
}

.slider-video {
    height: 300px;
}

.slider-video .swiper-slide video {
    max-height: 198px;
    padding: 5px;
    display:block;
    margin: 0 auto;
    width: 100%;
}
.slider-video .swiper-slide {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}
.video_screenshot_img {
    height: 385px;
}
.sale-time-label {
    color: var(--warning-red);
}
.sale-timer {
    padding-top: 5px;
}
.sale-timer b{
    font-size: 1.2em;
}
.sale-timer span{
    background: var(--warning-red);
    color: white;
    padding: 8px;
    margin-right: 5px;
    border-radius: 4px;
}
.price-block {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 4px 1px #bbb;
}
.buy-button-block {
    font-size: 1.5em;
}
.sum-sale {
    font-size: 2em;
    font-weight: bold;
}
.sum-before-discount {
    font-size: 1.3em;
    color: rgba(0,0,0,.5)!important;
}
.photostock_present {
    max-width: 300px;
    background: #435570;
    color: white;
    text-align: center;
}
.photostock_present span{
    font-size: 1.2em;
    margin: 10px 0;
    padding: 5px;
    border-radius: 4px;
}
.photostock_present_count {
    font-size: 5em!important;
    line-height: 1;
}
.product_buttons_mobile {
    display: none;
}
.price_mobile {
    display: none;
}
.client_declined_block {
    background: #b93233;
    padding: 20px;
    border-radius: 4px;
    margin: 20px 0;
}

.block-image-in-line {
    height: 385px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trailer-block {
    height: 385px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.all-photos-block {
    height: 385px;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
}
.all-photos-block img{
    max-height: 90px;
    margin: 2px;
}

.bounce-right {
    animation: bounce-right 1s ease-in-out infinite;
}

@keyframes bounce-right {
    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(10px, 0);
    }
}

.all-photos-block-text {
    //background: #fff;
    //border-radius: 10px;
    text-align: center;
    color: var(--warning-red);
    font-weight: bolder;
    font-size: 1.5em;
    //padding: 5px;
    //margin-top: 10px;
}

.slider-main {
    & .swiper-button-next, & .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 44px;
        height: 44px;
        margin-top: calc(-1 * var(--swiper-navigation-size)/ 2);
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #585454;
        background: #ffffffc9;
        border-radius: 20px;
        box-shadow: 0 0 4px 0 #bbb;
    }
    & .swiper-button-next:after, & .swiper-button-prev:after {
        font-size: 1.4em!important;
    }
}


@media (max-width: 767px){
    .slide-product-description {
        font-size: 0.8em;
    }
    #price_desktop {
        display: none;
    }
    .price_mobile {
        display: block;
    }
    #product_buttons_desktop {
        display: none;
    }
    .product_buttons_mobile {
        display: block;
    }
    //.sum-sale {
    //    font-size: 1.4em;
    //    padding-left: 5px;
    //}
    //.sum-before-discount {
    //    font-size: 1em;
    //}
    .sale-time-label {
        font-size: 1.4em;
    }
    //.sale-timer span{
    //    padding: 4px;
    //    margin-right: 0;
    //    border-radius: 4px;
    //    font-size: 0.8em;
    //}
    //.sale-timer b{
    //    font-size: 1em;
    //}
    #buy_button_fixed {
        background: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 2000;
        width: 100%;
        padding: 10px 30px;
        display: flex;
        justify-content: space-between;
    }
    .buy-button-block {
        font-size: 1.2em;
        width: 70%;
    }
    .footer {
        padding-bottom: 80px;
    }
    .slider-main .swiper-slide {
        width: 100%;
    }
    .video_screenshot_img {
        width: 90%;
        height: unset;
    }
    .image-in-line {
        max-width: 90%;
        max-height: 90%;
        height: unset;
    }
    .photostock_present {
        max-width: unset;
    }
    .slider-label {
        margin-left: 10px;
    }
    .all-photos-block img{
        max-height: 70px;
        margin: 2px;
    }
}
