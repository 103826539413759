/*modal*/
body.modal-open {

  & .header {
    transition: none;
    left: 0;
    padding-right: 16px;
  }
}

.modal-backdrop {
  background: rgba(247, 248, 247, 1);
}

.modal-content {
  background: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
}

.modal-dialog {
  max-width: 708px;
}

.modal-header {
  border-bottom: 1px solid #E3E3E3;
  padding: 51px 15px 38px 15px;
  display: block;
  text-align: center;

  & p {
    font-size: 17px;
    line-height: 153%;
    text-align: center;
    color: #000000;
    max-width: 560px;
    margin: 0 auto;
  }
}

.modal__wp {
  display: flex;
}

.modal__item {
  width: 50%;
  padding: 0 10px;

  &:first-child {
    & p {
      max-width: 235px;
    }
  }

  & .h3 {
    margin-bottom: 12px;
  }

  & p {
    font-size: 17px;
    line-height: 153%;
    color: #000000;
    margin-bottom: 20px;
  }

}

.modal-btn {
  font-size: 14px;
  line-height: 186%;
  text-decoration-line: underline;
  color: #597EDC;

  &:hover {
    text-decoration: none;
  }
}

.modal__img {
  margin-bottom: 28px;
}

.modal-body {
  padding: 4px 38px 30px 40px;
  border: none;

  & .h3 {
    font-weight: 600;
    font-size: 17px;
    line-height: 153%;
    color: #000000;
    margin-bottom: 20px;
    margin-top: 21px;
  }
}

.form__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -7px;

  & .form-group {
    width: calc(50% - 14px);
    margin: 10px 7px;

    & label {
      font-size: 12px;
      line-height: 14px;
      color: #939393;
      margin-bottom: 8px;
    }

    & .form-control {

      background: #F3F3F3;
      box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      height: 45px;
      padding: 10px 18px;
      font-size: 14px;
      line-height: 16px;

      color: #000000;

    }
  }
}

.modal-footer {
  border: none;
  display: flex;
  align-items: center;
  margin: 0 -12px;
  padding: 0 38px 44px 40px;

  & .btn {
    display: block;
    width: calc(50% - 24px);
    margin: 0 12px;
    text-align: center;

    &.btn-primary {
      text-align: center;

      &:after {
        display: none;
      }
    }

    &.btn-outline-primary {
      font-weight: bold;
      font-size: 20px;
      line-height: 35px;
      height: 66px;
    }
  }
}

.modal.modal-success {
  .modal-header {
    border-bottom: none;
    padding-top: 100px;
    padding-bottom: 21px;

    & p {
      max-width: 250px;
    }

    .modal__img {
      margin-bottom: 43px;
    }
  }

  & .modal-footer {
    text-align: center;
    justify-content: center;
    padding-bottom: 96px;

    & .btn.btn-outline-primary {
      width: auto;
      border: 1px solid #CBCBCB;
      border-radius: 5px;
      height: 35px;
      padding: 9px 30px;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #597EDC;

      &:hover {
        background: #597EDC;
        color: #FFFFFF;
      }
    }
  }
}


.fancybox-slide {
  padding: 15px;
}

//media
@media (max-width: 1599px) {
  .modal-footer .btn.btn-outline-primary {
    height: 50px;
    font-size: 18px;
    line-height: 27px;
  }
}

@media (max-width: 1023px) {
  .modal__img {
    margin-bottom: 20px;
  }
  .modal.modal-success .modal-footer {
    padding-bottom: 50px;
  }
  .modal.modal-success .modal-header {
    padding-top: 50px;
    padding-bottom: 21px;
  }
  .modal.modal-success .modal-header .modal__img {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .modal.modal-success .modal-footer {
    padding-bottom: 30px;
  }
  .modal.modal-success .modal-header {
    padding-top: 30px;
    padding-bottom: 21px;
  }
  .modal-header p {
    font-size: 15px;
  }
  .modal-header {
    padding: 25px 15px 20px 15px;
  }
  .modal-body .h3 {
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 16px;
  }
  .modal-body {
    padding: 0 15px 20px 15px;
  }
  .modal-footer {
    padding: 0 15px 20px 15px;
  }
}

@media (max-width: 567px) {
  .modal__item {
    width: 100%;
    padding: 0;
    margin: 0 0 10px 0;

    &:last-child {
      margin: 0;
    }
  }
  .modal__wp {
    display: block;
  }
  .modal__item p {
    margin-bottom: 15px;
  }
  .modal__item p {
    font-size: 15px;
  }
  .modal-footer .btn {
    width: calc(50% - 10px);
    margin: 0 5px;
  }
  .form__wrapper {
    margin: 0 0 15px 0;
  }
  .form__wrapper .form-group {
    width: 100%;
    margin: 0 0 10px 0;

    &:last-child {

      margin-bottom: 0;
    }
  }
}