.header {
  position: sticky;
  padding: 12px 0 15px 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
  z-index: 999;
  & .container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  & .btn.btn-outline-primary {
    position: absolute;
    left: 23px;
    top: 50%;
    transform: translateY(-50%);
  }
  & .logo {
    width: 126px;
    height: 50px;
  }
}

@media (max-width: 1023px) {
  .header .btn.btn-outline-primary {
    left: 15px;
  }
}
