@use "../variables";

//normalize для bootstrap
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

a, button, span {
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

input, textarea {
  display: block;
  width: 100%
}

table {
  width: 100%;
  border-collapse: collapse
}

a {
  background-color: transparent;
  text-decoration: none
}

img {
  border: none;
  max-width: 100%;
  height: auto
}

a, button {
  outline: 0;
  cursor: pointer
}

li {
  list-style: none
}

button {
  background: 0 0;
  border: none
}

a, button, input, textarea {
  outline: none;
  color: variables.$black;
  font-weight: inherit;
  -webkit-appearance: none;
  transition: all .5s ease;
}

a:hover {
  color: inherit;
}

:after, :before {
  box-sizing: border-box
}

textarea {
  resize: none;
  outline: 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: 0 0
}

input {
  outline: 0;
  -webkit-appearance: none;
  border-radius: 0;
  background: 0
}

input[type=number] {
  -moz-appearance: textfield
}

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button {
  -webkit-appearance: none
}

input[type=submit] {
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block
}

input[type=search]::-webkit-search-cancel-button {
  background: 0 0;
  display: none
}

.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}

.full-img img {
  width: 100%
}

figure {
}

figcaption {
  text-align: center;
  margin-top: 15px;
  text-align: left;
  font-weight: 300;
  font-size: .8em;
  opacity: .8;
}

[hidden] {
  display: none !important
}

.img-full {
  width: 100%;
}

.img-radius {
  border-radius: 10px;
}

.clearfix {
  &:after {
    content: '';
    clear: both;
    display: block
  }
}

b, strong {
  font-weight: 700;
}

.list-page {
  padding: 150px;
  @media (max-width: 1023px) {
    padding: 100px 15px;
  }

  & li {
    margin-bottom: 10px;

    & a {
      margin-bottom: 15px;
      font-size: 18px;

      &:hover {
        color: variables.$green;
      }
    }
  }
}

* a, span, input, textarea, label, :after, :before {
  transition: .5s;
}

p {
  margin: 0;
}

body {
  font-family: 'Roboto', 'Gilroy', sans-serif;
  font-size: 15px;
  color: #4A4F54;
  background: #fff;
}

h3, .h3 {
  font-weight: 600;
  font-size: 33px;
  line-height: 39px;
  color: #000000;
  @media (max-width: 1023px) {
    font-size: 26px;
    line-height: 34px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 26px;
  }
}

.main-wrapper > .card {
    background-color: transparent;
}

.main-wrapper {
  width: 100%;
  height: auto;
  min-height: 100vh;
    background: url(../../img/bg.png) no-repeat center 33px;
    background-color: #f7f8f7;
    background-size: contain;

  //&:before {
  //  content: '';
  //  display: block;
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  width: 100%;
  //  background: url(../../img/bg.png) no-repeat center;
  //  -moz-background-size: 100% 100%;
  //  background-size: 100% 100%;
  //  height: 800px;
  //  z-index: 2;
  //  @media (max-width: 1999px) {
  //    height: 700px;
  //  }
  //  @media (max-width: 1930px) {
  //    height: 600px;
  //  }
  //  @media (max-width: 1599px) {
  //    height: 500px;
  //  }
  //  @media (max-width: 1399px) {
  //    height: 400px;
  //    top: 30px;
  //  }
  //  @media (max-width: 1023px) {
  //    display: none;
  //  }
  //}
  & > .card > .container{
      min-height: 100vh;
  }
}

.swiper-notification {
  display: none;
}

.page-heading {
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
  margin-bottom: 91px;
  z-index: 3;

  & .title {
    margin-bottom: 29px;
    display: block;
  }

  & p {
    font-weight: 300;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    color: #585858;
  }

  @media (max-width: 1023px) {
    margin-bottom: 40px;
    & .title {
      margin-bottom: 15px;
    }
    & p {
      font-size: 20px;
      line-height: 30px;
    }
  }
  @media (max-width: 767px) {
    & p {
      font-size: 18px;
      line-height: 26px;
    }
  }
  @media (max-width: 567px) {
    .title {
      margin-bottom: 10px;
    }
    & p {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
@media (min-width: 1025px) {
  .wrapper {
    overflow: hidden;
  }
}

header {
  width: 100%;
}

main {
  flex: auto;
  display: block;
}

footer {
  margin: auto auto 0 auto;
  width: 100%;
}


@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1320px;
  }
}
