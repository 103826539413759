@use "../variables";
@use "../system/mixin.scss" as mixin;

.page-header {
    position: sticky;
    top: 0px;
    z-index: 10;
    transition: .5s;
}
//main styles for all screen
@media (min-width: 1025px) {
    .page-header {
        position: fixed;
        top: 33px;
        left: 36px;
    }
}

.logo {
  &__image {
    width: 202px;
    height: 80px;
    margin-bottom: 16px;

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__text {
    padding-left: 10px;
    color: #A4A4A4;
  }
}

.fullpage-wrapper {
  width: 100% !important;
  transform: none !important;

  .fp-section {
    &.active {
      visibility: visible;
      opacity: 1;
      z-index: 1;
    }

    width: 100% !important;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 0;
    transition: all .3s ease-in-out;

    .fp-slidesContainer {
      width: 100% !important;
      transform: none !important;

      .fp-slide {
        &.active {
          visibility: visible;
          opacity: 1;
          z-index: 1;
        }

        width: 100% !important;
        position: absolute;
        left: 0;
        top: 0;
        visibility: hidden;
        opacity: 0;
        z-index: 0;
        transition: all .3s ease-in-out;
      }
    }
  }
}

.fp-section {
  padding: 0;
}

body.fp-viewing-anchor-2-1, body.fp-viewing-anchor-2-2 {
  .page-form {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: .5s;
  }
}


//nav section

#fp-nav {
  left: 43px;
  width: 185px;
}

#fp-nav ul li, .fp-slidesNav ul li {
  width: auto;
  height: auto;
  margin: 0;
  padding-bottom: 35px;

  &:last-child {
    padding-bottom: 0;
  }
}

#fp-nav ul li a, .fp-slidesNav ul li a {
  &.active {
    & span:last-child {
      opacity: 1;
    }

    & span.fp-sr-only {
      color: #43A45E;
      font-weight: 600;
    }
  }

  & span {
    &:last-child {
      background: #43A45E;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;

      &:before {
        border-radius: 50%;
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../../img/icons/star.svg") no-repeat center;
        background-size: 15px 15px;
        background-color: variables.$green;
        transition: .3s;
        z-index: 1;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        height: 57px;
        width: 4px;
        background: #A1D9B6;
        border-radius: 90px;
        z-index: -2;
      }
    }

    &.fp-sr-only {
      padding-left: 45px;
      font-weight: 300;
      font-size: 17px;
      line-height: 20px;
      color: #585858;
      position: relative;
      width: auto;
      height: auto;
    }
  }
}

#fp-nav ul {
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 9px;
    top: 0;
    bottom: 6px;
    width: 4px;
    border-radius: 90px;
    background: #EFEFEF;
  }
}

#fp-nav ul li {
  &:last-child {
    a span:last-child {
      &:after {
        bottom: 0;
        top: auto;
      }
    }
  }
}

#fp-nav ul li .fp-tooltip {
  display: none;
}

//nav section end
.title {
  font-size: 79px;
  line-height: 79px;
  margin-bottom: 29px;
  font-family: 'Bebas Neue Cyrillic';
  background: linear-gradient(90deg, #5E6025 -1.41%, #213429 102.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #5E6025;
  display: table;
  text-transform: uppercase;
}

.screen {
  & .scrollable-content {
    max-width: 560px;
    width: 100%;
    z-index: 10;
  }

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 24%;
  z-index: 10;

  &.screen-big {
    & .scrollable-content {
      max-width: 725px;
    }
  }


  &__text {


    & p {
      font-weight: 300;
      font-size: 25px;
      line-height: 140%;
      color: #585858;

      & span {
        font-weight: 600;
      }
    }
  }

  &__wrap {

  }

  &__scroll {
    padding-right: 40px;
    overflow-x: hidden;
    max-height: 465px;
  }

  & .page-form__text {
    margin-top: 55px;
    margin-bottom: 26px;
    max-width: 370px;

    & p {
      text-align: left;
      max-width: 100%;
      font-weight: 300;
      font-size: 18px;
      line-height: 139%;
      color: #585858;
    }
  }

  .page-form__number {
    max-width: 402px;
  }

  & .btn.btn-primary {
    display: flex;
    align-items: center;
    max-width: 402px;
    height: 66px;
    width: 100%;
    font-size: 20px;
    line-height: 35px;

    & .btn__item:first-child {
      padding-right: 25px;
      margin-right: 21px;
      border-right: 1px solid #249B46;
    }

    & .btn__item:last-child {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
    }
  }

  &.screen-footer {
    padding-left: 100px;
    font-family: 'Sansita', sans-serif;
    justify-content: center;

    & .logo {
      width: 202px;
      height: 80px;
      margin: 0 auto 33px auto;
    }
  }

  &__company {
    font-size: 17px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 58px;
    font-family: 'Roboto', sans-serif;
  }

  &__name {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
  }

  &__info {
    margin-bottom: 52px;

    & p {
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__tel {
    margin-bottom: 50px;
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;
    color: #FFFFFF;
    display: flex;
    align-items: center;

    &:hover {
      color: variables.$green;
    }

    & span {
      margin-right: 37px;
      width: 15px;
      height: 22px;
      border: 1.5px solid #FFFFFF;
      border-radius: 3px;
      display: flex;
    }
  }

  &__wrapper {
    max-width: 440px;
    text-align: center;
    @include mixin.verticalCenter;
  }
}

//main styles for all screen end


//bg settings

.fullpage__bg {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  transform: translateX(300%);
  transition: .5s;
  user-select: none;
  pointer-events: none;

  & img {
    transition: .5s;
    width: 100%;
    height: 100%;
  }
}

.fp-viewing-anchor-1 {
  .fullpage__bg-1 {
    transform: translateX(0);
    transition: .5s;;
    transition-delay: 1s;
  }
}

.slide {
  .fullpage__bg {
    transform: translateX(200%);
    transition: .5s;
  }

  &.active {

    & .fullpage__bg {
      transform: translateX(0);
      transition: .5s;
    }
  }
}

body.fp-viewing-anchor-3 {
  .fullpage__bg {
    transform: translateX(0);
    transition: .5s;
  }
}

body.fp-viewing-anchor-4 .fullpage__bg-6 {
  transform: translateX(0);
  transition: .5s;
}

//body.fp-viewing-anchor-5 {
//  & .fullpage__bg-6 {
//    transform: translateX(0);
//    transition: .5s;
//  }
//
//  & .page-form {
//    transform: translateY(-50%) translateX(200%);
//    opacity: 0;
//    visibility: hidden;
//    transition: .5s;
//  }
//}

.section-5 {
  & .fullpage__bg {
    left: 0;
    right: auto;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateY(200%);
    z-index: -1;
  }
}

body.fp-viewing-anchor-5 {
  & .fullpage__bg.fullpage__bg-7 {
    transform: translateY(0);
    transition: .5s;
  }

  & .page-header {
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: .5s;
  }

  & .page-form {
    transform: translateY(-50%) translateX(200%);
    opacity: 0;
    visibility: hidden;
    transition: .5s;
  }

  #fp-nav ul li a span.fp-sr-only, .fp-slidesNav ul li a span.fp-sr-only {
    color: #FFFFFF;
  }
}

.fullpage__bg.fullpage__bg-7 {
  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

//bg settings end


//form on all screen
.page-form {
  transition: .5s;
  z-index: 999;
  position: fixed;
  right: 92px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 402px;

  &__title {
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #252933;
    margin-bottom: 15px;
  }

  &__text {
    margin-bottom: 25px;

    & p {
      max-width: 285px;
      margin: 0 auto;
      font-weight: 500;
      line-height: 140%;
      text-align: center;
      color: #939393;
    }
  }

  &__number {
    background: #F3F3F3;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-bottom: 17px;
    display: flex;
    align-items: center;
    height: 66px;

    & .form-control {
      height: 100%;
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 10px 26px;

      &:first-child {
        width: calc(100% - 160px);

        &::first-letter {
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          color: #35B459;
        }
      }

      &:last-child {
        width: 160px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: #A2A2A2;
        border-left: 1px solid #FFFFFF;
      }
    }
  }

  & .btn.btn-primary {
    height: 66px;
    display: flex;
    align-items: center;
    width: 100%;

    & .btn__item {
      display: flex;
      align-items: center;

      & span {
        display: flex;
        width: 25px;
        height: 20px;
        margin-right: 16px;
      }

      &:first-child {
        padding-right: 25px;
        margin-right: 21px;
        border-right: 1px solid #249B46;
      }

      &:last-child {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #FFFFFF;
      }
    }
  }
}

//form on all screen end

.section.section-2 {
  & .screen__text p {
    font-weight: 300;
    font-size: 18px;
    line-height: 194%;
    color: #2E2E2E;
  }
}


//fullpage slider
.fp-controlArrow {
  width: 45px;
  height: 45px;
  background: #EEEEEE;
  border: none;
  top: 70%;
  transition: .5s;

  &:hover {
    box-shadow: 0px 4px 4px rgba(41, 129, 66, 0.2);
    transition: .5s;
  }

  @media (max-height: 850px) {
    top: 80%;
  }
}

.fp-controlArrow.fp-next {
  left: 26.5%;
  right: auto;
  @media (max-width: 1939px) {
    left: 27.5%;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url("../../img/icons/next.svg") no-repeat center;
    background-size: 100% 100%;
    width: 15px;
    height: 11px;
  }
}

.fp-controlArrow.fp-prev {
  left: 24%;
  width: 45px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url("../../img/icons/prev.svg") no-repeat center;
    background-size: 100% 100%;
    width: 15px;
    height: 11px;
  }
}

//fullpage slider end


//faq screen

.faq-item {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .question {
    cursor: pointer;
    position: relative;

    &:hover {
      & span {
        color: variables.$green;
      }
    }

    &:after {
      transform: rotate(45deg);
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 5px;
      width: 8px;
      height: 8px;
      border-right: 2px solid #B9B9B9;
      border-bottom: 2px solid #B9B9B9;
      transition: .5s;
    }

    & span {
      font-weight: 600;
      font-size: 18px;
      line-height: 139%;
      color: #585858;
      max-width: 525px;
    }
  }

  & .answer {
    margin-top: 12px;

    & p {
      font-weight: 300;
      font-size: 17px;
      line-height: 176%;
      color: #585858;
    }
  }

  &.is_open {
    & .question {
      & span {
        color: #35B459;
      }

      &:after {
        transform: rotate(45deg) scale(-1);
        transition: .5s;
        border-right: 2px solid #35B459;
        border-bottom: 2px solid #35B459;
      }
    }
  }
}

//faq screen end


//scollbar

.simplebar-track.simplebar-vertical {
  background: #E8E8E8;
  opacity: 1;
  width: 2px;
  visibility: visible;
  border: none;
  pointer-events: auto;
}

.simplebar-scrollbar {
  background: #43A45E;
  opacity: 1;
  cursor: pointer;
  visibility: visible;
  pointer-events: auto;
}

//scollbar end


//reviews
.reviews {
  & .title {
    margin-bottom: 13px;
  }

  .screen__scroll {
    padding-left: 25px;
    margin-left: -25px;
  }

  &__slider {
    border: 1px dashed #000000;
    padding: 30px 60px 40px 45px;

    & .swiper-slide {
      height: auto;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 17px;
      width: 53px;
      height: 53px;
      background: #FFFFFF;
      z-index: 5;
      background-image: url("../../img/icons/star-green.svg");
      background-size: 30px 30px;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &__item {
    height: 100%;
    display: flex;
    flex-direction: column;

    & p {
      font-style: italic;
      font-size: 17px;
      line-height: 194%;
      color: #5F5F5F;
      margin-bottom: 13px;
    }
  }

  &__author {
    margin-top: auto;
    max-width: 70%;

    & a {
      font-size: 13px;
      line-height: 15px;
      color: #4A89E7;

      &:hover {
        color: variables.$green;
      }
    }
  }

  &__subtitle {
    font-weight: 300;
    font-size: 18px;
    line-height: 149%;
    color: #585858;
    margin-bottom: 53px;
  }

  &__name {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 10px;
  }

  &__btn {
    width: 45px;
    height: 45px;
    background: #EEEEEE;
    border: none;
    transition: .5s;
    top: auto;
    margin-top: 0;
    bottom: 50px;

    &:hover {
      box-shadow: 0px 4px 4px rgba(41, 129, 66, 0.2);
      transition: .5s;
    }

    &:after {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url("../../img/icons/prev.svg") no-repeat center;
      background-size: 100% 100%;
      width: 15px;
      height: 11px;
    }
  }

  &__prev {
    left: auto;
    right: 130px;
  }

  &__next {
    right: 77px;

    &:before {
      background: url("../../img/icons/next.svg") no-repeat center;
    }
  }
}


//reviews  end


//media

@media (max-width: 1699px) {
  .page-form {
    right: 52px;
  }
  .title {
    font-size: 69px;
    line-height: 69px;
  }
}

@media (max-width: 1599px) {
  .page-form .btn.btn-primary .btn__item:first-child {
    padding-right: 15px;
    margin-right: 11px;
  }
  .screen__text p {
    font-size: 20px;
  }
  .screen .scrollable-content {
    max-width: 460px;
  }
  .reviews__item p {
    font-size: 15px;
  }
  .title {
    font-size: 59px;
    line-height: 59px;
    margin-bottom: 10px;
  }
  .page-form {
    width: 360px;
  }
  .page-form__number .form-control {
    padding: 10px 15px;
  }

  .screen.screen-big .scrollable-content {
    max-width: 650px;
  }
  .reviews__subtitle {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .faq-item .question span {
    font-size: 16px;
  }
  .faq-item {
    margin-bottom: 15px;
  }
  .faq-item .answer p {
    font-size: 15px;
  }
  .reviews__slider {
    padding: 25px 40px 25px 30px;
  }
  .screen .page-form__text {
    margin-top: 25px;
    margin-bottom: 15px;
    max-width: 370px;
  }
  .reviews__btn {
    bottom: 30px;
  }
}

@media (max-width: 1439px) {
  .screen .scrollable-content {
    max-width: 440px;
  }
  .screen__info {
    margin-bottom: 30px;
  }
  .screen__company {
    margin-bottom: 30px;
  }
  .screen__tel {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .page-form__title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .page-form__text p {
    font-size: 13px;
    max-width: 243px;
  }
  .page-form__number {
    height: 40px;
  }
  .page-form .btn.btn-primary {
    height: 40px;
  }
  .faq-item .answer p {
    font-size: 14px;
  }
  .title {
    font-size: 49px;
    line-height: 49px;
  }
  .screen.screen-big .scrollable-content {
    max-width: 650px;
  }
  .page-form {
    width: 320px;
  }
  .reviews__slider {
    padding: 25px 30px 20px 30px;
  }
  .reviews__btn {
    bottom: 30px;
  }
  .screen__text p {
    font-size: 18px;
  }
  .reviews__item p {
    font-size: 15px;
  }
  .section.section-2 .screen__text p {
    font-size: 16px;
    line-height: 184%;
  }
  .page-form .btn.btn-primary .btn__item span {
    width: 20px;
    height: 20px;
    font-size: 22px;
    display: flex;
    margin-right: 10px;
  }
  .page-form .btn.btn-primary .btn__item:last-child {
    font-size: 11px;
    line-height: 14px;
  }
  .screen .btn.btn-primary {
    height: 40px;

    .btn__item:last-child {
      font-size: 11px;
      line-height: 14px;
    }

    .btn__item {
      display: flex;
      align-items: center;
    }

    .btn__item span {
      width: 20px;
      height: 20px;
      font-size: 22px;
      display: flex;
      margin-right: 10px;
    }
  }
  .page-form__text {
    margin-bottom: 15px;
  }
  .page-header {
    left: 20px;
  }
  #fp-nav {
    left: 20px;
  }
  #fp-nav ul li, .fp-slidesNav ul li {
    padding-bottom: 15px;
  }
  #fp-nav ul li a span.fp-sr-only, .fp-slidesNav ul li a span.fp-sr-only {
    padding-left: 30px;
    font-size: 15px;
  }
  .screen {
    padding-left: 18%;
  }
  .fp-controlArrow.fp-prev {
    left: 18%;
  }
  .fp-controlArrow.fp-next {
    left: 21.5%;
  }
}

@media (max-width: 1299px) {
  .page-form .btn.btn-primary .btn__item:first-child {
    font-size: 16px;
    line-height: 21px;
  }
  #fp-nav ul li a span.fp-sr-only, .fp-slidesNav ul li a span.fp-sr-only {
    font-size: 13px;
  }
  #fp-nav {
    left: 10px;
  }
  .logo__image {
    width: 170px;
    height: 70px;
    margin-bottom: 12px;
  }
  .logo__text {
    font-size: 14px;
  }
  #fp-nav ul li, .fp-slidesNav ul li {
    padding-bottom: 10px;
  }
  #fp-nav ul li a span:last-child:after, .fp-slidesNav ul li a span:last-child:after {
    height: 37px;
  }
  .title {
    font-size: 39px;
    line-height: 39px;
  }
  .faq-item .question span {
    font-size: 15px;
    max-width: 430px;
  }
  .faq-item .answer p {
    font-size: 13px;
  }

  .screen.screen-big .scrollable-content {
    max-width: 500px;
  }
  .page-form__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .page-form {
    width: 280px;
  }
  .page-form .btn.btn-primary .btn__item:first-child {
    padding-right: 10px;
    margin-right: 7px;
  }
  .page-form .btn.btn-primary .btn__item span {
    width: 15px;
    height: 15px;
    font-size: 15px;
    margin-right: 5px;
  }
  .screen .scrollable-content {
    max-width: 400px;
  }
  .screen__text p {
    font-size: 16px;
  }
  .screen .page-form__text p {
    font-size: 16px;
  }
  .screen__text p {
    font-size: 13px;
  }
  .reviews__name {
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 5px;
  }
  .reviews__btn {
    bottom: 25px;
  }
  .fp-controlArrow.fp-next {
    left: 23%;
  }
  .screen__tel {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .screen__info {
    margin-bottom: 15px;
  }
  .screen__name {
    margin-bottom: 15px;
  }
  .screen__company {
    margin-bottom: 15px;
  }
  .screen.screen-footer .logo {
    width: 170px;
    height: 70px;
    margin: 0 auto 15px auto;
  }
}


//media

@media (max-width: 1024px) {
  .fullpage-wrapper .fp-section {
    position: relative;
    left: auto;
    top: auto;
    opacity: 1;
    visibility: visible;
    height: auto !important;
  }
  .fullpage-wrapper {
    padding-top: 60px;
  }
  .page-header {
    @include mixin.verticalCenter;
    left: 0;
    top: 0;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
    text-align: center;
    padding: 5px;
  }
  .logo__image {
    width: 87px;
    height: 27px;
    margin-bottom: 5px;
  }
  .logo__text {
    padding-left: 0;
    font-size: 12px;
  }
  .logo {
    text-align: center;
    @include mixin.verticalCenter;
  }
  .section {
    padding: 40px 25px;
  }
  .screen {
    padding: 0;
  }
  .fullpage__bg {
    display: none;
  }
  .screen .scrollable-content {
    max-width: 100%;
  }
  .screen__text {
    text-align: left;
  }
  .title {
    display: block;
  }
  .section.section-1 {
    padding-top: 80px;
    padding-bottom: 40px;

    & .screen__text {
      text-align: center;
    }
  }
  .fp-controlArrow {
    display: none;
  }
  .screen__scroll {
    overflow: visible;
    max-height: inherit;
    padding: 0;
  }
  .fp-slidesContainer {
    float: none;
  }
  .fp-slide {
    float: none;
    margin-bottom: 30px;
    text-align: left;
  }
  .fullpage-wrapper .fp-section .fp-slidesContainer .fp-slide {
    opacity: 1;
    position: relative;
    left: auto;
    top: auto;
    visibility: visible;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .simplebar-track.simplebar-horizontal {
    display: none !important;
  }
  .simplebar-track.simplebar-vertical {
    display: none !important;
  }
  .simplebar-track {
    display: none !important;
  }
  .screen.screen-big .scrollable-content {
    width: calc(100% - 310px);
    max-width: 100%;
  }
  .title {
    font-size: 29px;
    line-height: 29px;
  }
  .page-form {
    right: 25px;
  }
  .fp-slides {
    width: calc(100% - 310px);
  }
  .screen.screen-footer {
    padding-left: 0;
  }
  .fullpage__bg.fullpage__bg-7 {
    display: block;
    transform: none;
  }
  .screen.screen-footer {
    .screen__wrapper {
      margin: 0 auto;
    }
  }
  .reviews__btn {
    width: 30px;
    height: 30px;
  }
  .reviews__prev {
    right: 50px;
  }
  .reviews__next {
    right: 15px;
  }
  .reviews__slider:before {
    left: 8px;
    top: 17px;
    width: 33px;
    height: 33px;
    background-size: 20px 20px;
  }
  .reviews .screen__scroll {
    padding-left: 25px;
    margin-left: -25px;
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .screen__wrapper {
    max-width: 300px;
  }
  .screen__tel span {
    margin-right: 15px;
    width: 10px;
    height: 15px;
  }
  .screen.screen-footer .logo {
    width: 120px;
    height: 50px;
    margin: 0 auto 15px auto;
  }
  .screen__tel {
    font-size: 16px;
    margin-bottom: 14px;
  }
  .screen__company {
    font-size: 15px;
    line-height: 16px;
  }
  .screen__info p {
    font-size: 12px;
    line-height: 14px;
  }
  .screen__name {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
  }
  .page-form {
    display: none;
  }
  .fp-slides {
    width: 100%;
  }
  .screen.screen-big .scrollable-content {
    width: 100%;
  }
  .screen .page-form__text {
    max-width: 100%;
  }
  .screen .page-form__number {
    max-width: 100%;
  }
  .screen .btn.btn-primary {
    max-width: 100%;
    justify-content: center;
  }
  .screen__text {
    text-align: center;
  }
  .reviews__item {
    text-align: left;
  }
  .faq-item {
    text-align: left;
  }
  .faq-item .question:after {
    right: 5px;
  }
  .screen .scrollable-content {
    width: 100%;
  }
  .section {
    padding: 25px 15px;
  }
  .faq-item .question span {
    padding-right: 15px;
  }
}

@media (max-width: 567px) {
  .reviews__author {
    max-width: 100%;
  }
  .reviews__btn {
    bottom: 20px;
  }
  .section.section-1 {
    padding-top: 60px;
    padding-bottom: 35px;
  }
  .reviews__next {
    right: 30%;
  }
  .reviews__prev {
    right: auto;
    left: 35%;
  }
  .reviews__slider {
    padding: 25px 30px 50px 30px;
  }
  .reviews__author a {
    font-size: 12px;
    line-height: 13px;
  }
  .screen .btn.btn-primary .btn__item:first-child {
    padding-right: 10px;
    margin-right: 10px;
  }
  .title {
    font-size: 25px;
    line-height: 25px;
  }
}
